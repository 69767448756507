import React from 'react';
import './App.css';
import Home from './components/Home/Home';
import {BrowserRouter as Router, Routes, Route, Link, BrowserRouter} from "react-router-dom";
import Blog from './components/Blog/Blog'

function App() {

    

  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path='blog/*' element={<Blog />} />
        </Route>
        
        
        
      </Routes>
    </BrowserRouter>

    </div>
    
  );
}

export default App;
