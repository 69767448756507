import { homedir } from 'os';
import React, { HTMLProps } from 'react';
import { Type } from 'typescript';
import Hexagon from 'react-hexagon';
import Header from '../Header/Header';
import { Link } from "react-router-dom";
import Constants from '../../Constants';
import './Home.css'

type HomeProps = {
}

const Home:React.FC<{}> = ( ):JSX.Element | null => {

    const hexLeft = (Constants.hexRadiusVW * 1.16 / 4)

    return (
        <div className='home'>
            <style>{`

                .home .cluster{
                    height: ${Constants.hexRadiusVW}vw;
                }

                .home .cluster .hex:nth-child(even){
                    top: -${Constants.hexRadiusVW/2}vw;
                    left: -${hexLeft}vw;
                  }

                `}
            </style>

            <Header headerPic='/images/profile.png' headerText='Evan Price Software' />

            <div className='cluster'>
                <div className='hex'><Hexagon  flatTop='true' style={{stroke:'white', strokeOpacity: Constants.hexBorderOpacity, fill:'grey'}} ></Hexagon></div>
                <div className='hex' id='blog'><div className='link'><Link to='/blog'>Blog</Link></div><Hexagon flatTop='true' 
                style={{stroke:'white', strokeOpacity: Constants.hexBorderOpacity}} 
                backgroundImage='images/blog.png' backgroundScale='1.1'></Hexagon></div>
            </div>
            
        </div>
    )

};

export default Home;