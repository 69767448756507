import React, { HTMLProps } from 'react';
import ReactMarkdown from 'react-markdown'
import Constants from '../../Constants';
import Header from '../Header/Header';
import './Blog.css'

type BlogProps = {}

const Blog:React.FC<{}> = ():JSX.Element | null => {

    return (
        <div className='blog'>
            {/* <style>{`
            .blog .text {
                top: -${Constants.hexRadiusVW/2}vw;
            }

            `}</style> */}


            <Header headerPic='images/blog.png' headerText='EPS Blog' backgroundScale='1.1' />
            <div className='text'>
            <ReactMarkdown>{`
# Introduction 

In this blog I'm going to use this text for the image, this isn't a real blog post. But I don't want to use Lorem Ipsum so I'm trying to type things that would make sense.
Four score and seven years ago I wasn't alive. What is even a score anyway? I think a score is like 20 years. But this blog is going to be all about software design and things like that, 
and will hopefully draw a considerable crowd. This paragraph needs to be a little longer, so this sentence is here.

## The second header, for effect!

That was a pretty good fake first paragraph. I just added indenting to the paragraph so it doesn't look like this site was designed by a moron. People seem to forget about paragraph indenting. 
Paragraph indenting was created in 1997 when someone hit space on their typewriter on accident and thought it looked cool and just went with it. The rest is history. The second paragraph needs to
be longer than the first, and the third will be shorter than the first, I'm thinking that's going to look pretty nice for a picture. I still haven't gotten the scrollbar to show up on my 
browser, so I guess this one keeps getting longer. There are going to be Factorio posts too, Factorio Fridays. I think that sounds nice. Okay one more sentence here that needs to have a few 
more.

I need to figure out how I'm going to handle images. Probably set up an api. I probably should try and get some code snippets for the picture, so the API will look something like

            GET /blog/images/post/{post_number}/{id}
            RETURN
                Image Data

There wouldn't be a save endpoint, not publicly anyway. Eventually I'll build the blog out to include and editor and stuff. To get started though I just need to display text. Okay one more 
sentence ought to do it. I think it looks good!



            `}</ReactMarkdown>
            </div>
        </div>
    )

};

export default Blog;