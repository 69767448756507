import React, { HTMLProps } from 'react';
import { Type } from 'typescript';
import Hexagon from 'react-hexagon';
import { Link } from "react-router-dom";
import './Header.css'
import Constants from '../../Constants'

type HeaderProps = {
    headerPic: string;
    headerText: string;
    backgroundScale?: string;

}

const Header:React.FC<{headerPic: string, headerText: string, backgroundScale?: string }> = (props:HeaderProps):JSX.Element | null  => {
    
    const bgScale = props.backgroundScale ?? '1.1';

    return (

        <div className='header'>
            <style>{`
                .header {
                    height: ${Constants.hexRadiusVW}vw;
                }

                .header-text{
                    height: ${Constants.hexRadiusVW/2}vw;
                }
                `}
            </style>


            <Hexagon flatTop='true' backgroundImage={props.headerPic} className='hex' backgroundScale={bgScale} style={{stroke:'white', strokeOpacity: Constants.hexBorderOpacity, }} />
            <text className='header-text'>{props.headerText}</text>
        </div>
            
    )

};

export default Header;